<script setup>
import Button from '@/Components/Button/Button.vue';
import { mapErrors } from '@/utilities';
import { useForm } from '@inertiajs/vue3';
import { inject } from 'vue';

// Icons
import saveIcon from '@i/save-white.svg';

const route = inject('route');

const props = defineProps({
    faq: Object,
    categoryOptions: Object,
});

const form = useForm({
    ...props.faq,
});

const sendForm = () => {
    form.put(route('staff.faqs.update', { faq: props.faq.id }), {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => (form.errors = mapErrors(errors)),
    });
};
</script>
<template>
    <div class="grid gap-x-8 gap-y-2 #md:grid-cols-2">
        <FormKit
            :label="$t('Category')"
            type="select"
            name="category_id"
            :options="{ ...categoryOptions }"
            select-icon="down"
            v-model="form.category_id"
            :value="form.category_id"
            :errors="form.errors.category_id"
        />

        <FormKit
            type="text"
            name="question_nl"
            :label="$t('Question (nl)')"
            v-model="form.question.nl"
            :errors="form.errors.question"
            :value="form.question.nl"
        />

        <FormKit
            type="text"
            name="question_en"
            :label="$t('Question (en)')"
            v-model="form.question.en"
            :errors="form.errors.question"
            :value="form.question.en"
        />

        <FormKit
            type="textarea"
            name="answer_nl"
            :label="$t('Answer (nl)')"
            v-model="form.answer.nl"
            :errors="form.errors.answer"
            :value="form.answer.nl"
        />

        <FormKit
            type="textarea"
            name="answer_en"
            :label="$t('Answer (en)')"
            v-model="form.answer.en"
            :errors="form.errors.answer"
            :value="form.answer.en"
        />

        <FormKit
            type="number"
            name="order_column"
            :label="$t('Order Column')"
            v-model="form.order_column"
            :errors="form.errors.order_column"
            :value="form.order_column"
        />

        <FormKit
            :label="$t('Show to Employers')"
            type="select"
            select-icon="down"
            name="employer"
            v-model="form.employer"
            :value="form.employer"
            :errors="form.errors.employer"
            :options="{
                0: $t('No'),
                1: $t('Yes'),
            }"
        />

        <FormKit
            :label="$t(`Show to Fl@xr's`)"
            type="select"
            select-icon="down"
            name="flexer"
            v-model="form.flexer"
            :value="form.flexer"
            :errors="form.errors.flexer"
            :options="{
                0: $t('No'),
                1: $t('Yes'),
            }"
        />

        <div class="flex justify-end">
            <Button
                orange
                type="button"
                :processing="form.processing"
                :recentlySuccessful="form.recentlySuccessful"
                :text="$t('Save')"
                :icon="saveIcon"
                @click="sendForm"
            />
        </div>
    </div>
</template>
